<template>
    <div>
        <van-popup v-model="ruleShow" :close-on-click-overlay="false">
            <p>Rules</p>
            <div class="rule">
                <div class="rule-title">1. How to participate in this activity</div>
                <div class="rule-content">
                    <span>-Only Canada based users can be participated. Log in <a href="https://www.moobyyoho.com">MoobyYoho.com</a> to initiate the activity.Participant needs to save item’s event poster or link, and forward it to friends via social media in Canada area. In order to get cheaper price of the item, your product’s poster needs to be shared or link needs to be scanned.</span>
                    <br>
                    <span>-Please note, the more friends you invited to share, the more discounts you can get on one item.
Each user(multiple devices in the same network environment are considered as the same user) can only help one user to get discount.</span>
                </div>
                <div class="rule-title">2. How to purchase discount product</div>
                <div class="rule-content">
                    All discount item on Moobyyoho has certain friends help time, once the time is used up, the item cannot continue to get the discount anymore. There is limit number of discount item, the activity will be end once the items are sold.
                </div>
                <div class="rule-title">3. Discount Amount</div>
                <div class="rule-content">
                    The discount amount from each friend help is randomly deducted  by one time share, until participant get the maximum discount amount.
                </div>
                <div class="rule-title">4. Activity Time Range</div>
                <div class="rule-content">
                    All the discount item have a discount time frame, please make sure purchase the item before it ends. If the activity ends, the discount will be note as expired and invalid to purchase.
                </div>
            </div>
            <div class="confirm" @click.stop="sure">Confirm</div>
        </van-popup>
    </div>
</template>
<script>
export default {
    props: ['ruleShow'],
    data() {
        return {
        }
    },
    created() {
        
    },
    methods: {
        sure() {
            this.$emit('rule')
        }
    }
};
</script>
<style scoped lang='less'>
    .van-popup {
        width: 291px;
        padding: 20px 20px 0;
        background: #fff;
        border-radius: 12px;
        font-size: 14px;
        box-sizing: border-box;
        p {
            text-align: center;
            margin-bottom: 12px;   
            font-size: 16px;       
        }
        .rule {
            overflow-x: hidden;
            overflow-y: auto;
            height: 377px;
            .rule-title {
                margin-bottom: 12px;
            }
            .rule-content {
                margin-bottom: 20px;
                a {
                    color: #48A2FF;
                }
            }
        }
        .confirm {
            margin-top: 5px;
            box-shadow: inset 0px 1px 0px rgba(153, 153, 153, 0.25);
            text-align: center;
            padding: 13px 0;
            color: #FF5049;
        }
        
    }
</style>