<template>
    <section class="m-haggle m-scroll" ref="scroll"  @scroll="handleScroll($event)">
        <div class="header" :style="`background:rgba(255,255,255,${(this.offsetTop-100) / 100});`">
            <img @click="$router.go(-1)" src="@/assets/goods/icon_back_2@2x.png" alt="back">
            <span :style="`opacity:${(this.offsetTop -60)  / 60};`">All My Super Deal</span>
            <router-link to="/en/cart" >
                <img src="@/assets/goods/cart.png" alt="img" :class="{'cart':cartAnimate}">
                <m-dot :animate="dotAnimate" ref="dot"></m-dot>
            </router-link>
        </div>
        <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`">
            <div class="rule" @click="rule">Rules</div>
        </div>
        <div class="haggle-goods">
            <div class="haggle-title">
                <img src="@/assets/haggle/Group 3086.png" alt="">
                <span>All My Super Deal</span>
                <img src="@/assets/haggle/Group 3086.png" alt="">
            </div>
            <van-list 
                v-model="loading"
                :finished="finished"
                finished-text="no more"
                loading-text="Loading..."
                @load="onLoad" >
                <ul class="m-goods-columns m-pad">
                    <li class="goods-columns-1" v-for="(item,index) in myHaggleList" :key="index">
                        <div class="expired" v-if="item.bargain_goods.remaining_time <= 0"><img src="@/assets/haggle/ends_en.png" alt=""></div>
                        <div class="m-goods"
                            @click="$router.push({path:`/en/haggleDetail/${item.bargain_goods.goods_id}`,query:{haggle_id:item.id}})" 
                            :class="item.order && item.order.order_status != 0 ? 'disable' : ''">
                            <div class="haggling" v-if="(item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2) == item.sum">
                                {{item.order ? item.order.order_status != 0 ? 'bought' : 'chopped' : 'chopped'}}
                            </div>
                            <img class="goods-img" v-if="item.bargain_goods_sku.goods_sku.image" :src="item.bargain_goods_sku.goods_sku.image.remote_url" alt="goods">
                            <div class="goods-info">
                                <p class="van-multi-ellipsis--l2">{{item.bargain_goods.goods_info.name}}</p>
                                <div class="count">
                                    <span>Ends in</span>
                                    <van-count-down :time="item.bargain_goods.remaining_time*1000" format="DD Days HH:mm:ss" />
                                </div>
                                <div class="price">
                                    <span>{{item.bargain_goods_sku.goods_sku.attr_symbol_string_en}}</span>
                                    <!-- <span>Current Price：<span>${{(item.bargain_goods_sku.goods_sku.price-item.sum).toFixed(2)}}</span> </span> -->
                                </div>
                                <div class="goods-discount">
                                    <div class="discount" v-if="(item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2) != item.sum">
                                        Congrats! You got 
                                        <span>${{item.sum? item.sum : 0}}</span> off<br>
                                        (Maximum obtainable <span>${{(item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2)}}</span> off) 
                                    </div>
                                    <!-- <div class="discount" v-if="(item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2) != item.sum">
                                        Current Price:<span>${{(item.bargain_goods_sku.goods_sku.price-item.sum).toFixed(2)}}</span><br>
                                        （Lowest Price${{item.bargain_goods_sku.sales_price}}）
                                    </div> -->
                                    <div class="discount" v-else>
                                        Got Discount for <span>${{item.sum}}</span>
                                    </div>
                                    <van-progress :percentage="item.get_off" stroke-width="8" :show-pivot="false" />
                                </div>
                            </div>
                        </div>
                        <div v-if="item.order ? item.order.order_status != 0? false: true : true" class="goods-bottom">
                            <!-- <div class="gap" v-if="(item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2) != item.sum"><span>${{(item.bargain_goods_sku.goods_sku.price - item.bargain_goods_sku.sales_price - item.sum).toFixed(2)}}</span> to reach</div>
                            <div class="gap" v-else></div> -->
                            <div class="gap">Current Price:<span>${{(item.bargain_goods_sku.goods_sku.price-item.sum).toFixed(2)}}</span> </div>
                            <div class="goods-btn" v-if="item.bargain_goods_sku.cut_type == 0 || 
                                    item.sum == (item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price).toFixed(2)">
                                <p @click="addCart(item)" v-if="item.buy_number_count < item.bargain_goods_sku.buy_limit">Add to Cart</p>
                                <div @click="buyNow(item)">
                                    <span>Buy Now</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </van-list>
        </div>
        <!-- 活动规则 -->
        <m-rule @rule="rule" :ruleShow="ruleShow"></m-rule>
        <!-- 数量弹窗 -->
        <m-num :maxNum="maxNum" :show="showNum" @close="val=>showNum=val" @confirm="handleConfirm"></m-num>
    </section>
</template>
<script>
import MDot from '@/components/en/m-dot.vue'
import MNum from '@/components/en/m-num.vue'
import MRule from './rule.vue'
import {my_haggle} from '@/api/zh/haggle.js'
import { addCart } from '@/api/zh/cart.js'
export default {
    components:{ MDot, MRule, MNum },
    data() {
        return {
            offsetTop : 0,
            dotAnimate: false,
            cartAnimate: false,
            time: 0,
            ruleShow: false,
            loading: false,
            finished: false,
            page: 0,
            myHaggleList: [],
            showNum: false,
            maxNum: '',
            buyObj: {}
        }
    },
    created() {
        // this.getMyHaggleList()
    },
    methods: {
        handleScroll(e) {
            this.offsetTop = e.target.scrollTop
        },
        rule() {
            this.ruleShow = !this.ruleShow
        },
        onLoad() {
            this.page ++
            this.getMyHaggleList()
        },
        getMyHaggleList() {
            my_haggle({page: this.page}).then(res => {
                if(res) {
                    this.myHaggleList = this.myHaggleList.concat(res.data.data)
                    this.myHaggleList.forEach(item => {
                        item.get_off = item.sum / (item.bargain_goods_sku.goods_sku.price-item.bargain_goods_sku.sales_price)*100
                    })
                    if(this.myHaggleList.length >= res.data.total) {
                        this.finished = true
                    }
                }
            }).finally(() => {
                this.loading = false
            })
        },
        // 加入购物车
        addCart(item) {
            let params = {
                goods_id: item.bargain_goods.goods_id,
                barcode: item.bargain_goods_sku.goods_sku.barcode,
                bargain_goods_sku_id: item.bargain_goods_sku_id,
                buy_number: 1
            }
            addCart(params).then(res => {
                if(res) {
                    this.handleCartSuccess()
                    let timer = setTimeout( () => {
                        this.$store.commit('addCart', 1)
                        clearTimeout(timer)
                    }, 600)
                }
            })
        },
        // sku 弹窗加入购物车成功回调
        handleCartSuccess(){
            this.cartAnimate = true

            let timer = setTimeout( () => {
                this.cartAnimate = false
                this.dotAnimate = true 
                let timer1 = setTimeout(()=> {
                    this.dotAnimate = false
                        clearTimeout(timer1)
                    },400)
                clearTimeout(timer)
            },660)
            
        },
        // 立即购买
        buyNow(item) {
            if(item.order) {
                if(item.order.order_status == 0) {
                    this.$router.push('/en/order/0?back=account')
                }
            } else {
                this.maxNum = item.bargain_goods_sku.buy_limit
                this.showNum = true
                this.buyObj = {
                    buyId: item.bargain_goods_sku.goods_sku_id,
                    haggle_id: item.bargain_goods_sku_id
                }
            }
        },
        handleConfirm(num) {
            this.buyObj.buyNum = num
            this.showNum = false
            this.$router.push({ path: '/en/buynow', query: this.buyObj  })
        }
    }
};
</script>
<style scoped lang="less">
    .m-haggle {
        width: 100%;
        height: 100%;
        padding-top: 44px;
        background-color: #F25149;
        overflow-x: hidden;
        overflow-y: auto;
        .header {
            width: 100%;
            height: 44px;
            padding: 0  16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #79A8F0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 13;
            .cart {
                animation: cart-shake 0.33s infinite ;
            }
            @keyframes cart-shake {
                0% {
                transform: rotate(-15deg);
                }
                14% {
                transform: rotate(-30deg);
                }
                28% {
                transform: rotate(-15deg);
                }
                42% {
                transform: rotate(0deg);
                }
                56% {
                transform: rotate(15deg);
                }
                70% {
                transform: rotate(30deg);
                }
                84% {
                transform: rotate(10deg);
                }
                100% {
                transform: rotate(00deg);
                }
            }
            &>img {
                width: 30px;
                height: 30px;
            }
            &>span {
                font-size: 4.8vw;
                font-weight: 400;
                color: #333333;
                letter-spacing: 1px;
            }
            .search {
                width: 255px;
                height: 32px;
                background-color: #fff;
                position: relative;
                border-radius: 30px;
                &>img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 6px;
                    left: 12px;
                }
            }
            &>a {
                position: relative;
                display: flex;
                align-items: center;
                &>img {
                    width: 30px;
                    height: 30px;
                }
            }
            
        }
        .header-bg {
            width: 100%;
            height: 260px;
            background-size: cover;
            position: fixed;
            top: 0;
            left: 0;
            z-index:8;
            transition: all 0.3s;
            background: url('../../../assets/haggle/header-bg_en.png') no-repeat;
            background-size: 100%;
            .rule {
                position: absolute;
                right: 0;
                top: 118px;
                padding: 6px 16px;
                border-radius: 15px 0 0 15px;
                font-size: 12px;
                border: 1px solid #fff;
                border-right: none;
                background: linear-gradient(90deg, #6B84F4 0%, #29FFCC 100%);;
                color: #fff;
            }
        }
        .haggle-goods {
            margin: 155px auto 12px;
            padding: 46px 0 12px;
            border: 2px solid #fff;
            border-radius: 16px;
            width: 359px;
            position: relative;
            z-index: 10;
            background: #FEEACC;
            .haggle-title {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                width: 260px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url('../../../assets/haggle/title.png') no-repeat;
                background-size: 100% 100%;
                img {
                    width: 16px;
                    height: 10px;
                    &:nth-of-type(2) {
                        transform: rotate(180deg);
                    }
                }
                span {
                    margin: 0 8px;
                }
            }
            .m-pad {
                padding: 0 8px;
            }
            .goods-columns-1 {
                background: #fff;
                width: 100%;
                border-radius: 12px;
                background: #fff;
                margin-bottom: 12px;
                box-shadow: 0px 2px 16px rgba(204, 204, 204, 0.2);
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 103px;
                    height: 156px;
                    position: absolute;
                    top: 8px;
                    right: 0;
                    background: url('../../../assets/haggle/Vector\ 20.png') no-repeat;
                    background-size: 100% 100%;
                    z-index: 1;
                }
                .expired {
                    position: absolute;
                    border-radius: 12px;
                    background: rgba(0, 0, 0, 0.6);
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    z-index: 10;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
                .m-goods {
                    display: flex;
                    position: relative;
                    padding: 8px;
                    z-index: 2;
                    .haggling {
                        padding: 3px 8px;
                        position: absolute;
                        top: 8px;
                        left: 8px;
                        font-size: 12px;
                        color: #fff;
                        background: url('../../../assets/haggle/Rectangle 102.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &>.goods-img {
                        width: 110px;
                        height: 110px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                    .goods-info {
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        flex: 1;
                        &>p {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 400;
                            color: #333;
                        }
                        .price {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 5px;
                            &>span:nth-child(1) {
                                color: #666;
                                font-size: 12px;
                                margin-left: 5px;
                            }
                            &>span:nth-child(2) {
                                font-size: 12px;
                                span {
                                    color: #EF5D56;
                                    font-size: 14px;
                                    font-weight: bold;
                                }
                            }
                        }
                        .count {
                            display: flex;
                            font-size: 12px;
                            color: #EF4422;
                            border: 1px solid #EF4D2D;
                            width: 170px;
                            height: 16px;
                            align-items: center;
                            // justify-content: center;
                            border-radius: 8px;
                            margin: 4px 0;
                            padding-left: 8px;
                            box-sizing: border-box;
                            span {
                                display: inline-block;
                                line-height: 14px;
                                margin-right: 5px;
                            }
                            .van-count-down {
                                height: 14px;
                                line-height: 14px;
                                color: #EF4422;
                                font-size: 12px;
                            }
                        }
                        .goods-discount {
                            padding-right: 10px;
                            .discount {
                                text-align: center;
                                font-size: 12px;
                                color: #808080;
                                span {
                                    color: #EF5D56;
                                    font-weight: bold;
                                }
                            }
                            .van-progress {
                                margin-top: 4px;
                                /deep/.van-progress__portion {
                                    width: 100%;
                                    background: linear-gradient(90.19deg, #6EFFF6 -6.08%, #35B6FF 105.58%);
                                }
                            }
                        }
                        .goods-price {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            &>div:nth-child(1) {
                                .down {
                                    width: 42px;
                                    text-align: center;
                                    color: #fff;
                                    font-size: 10px;
                                    background: linear-gradient(315deg, #F03C18 0.05%, #F06448 100%);
                                    border-radius: 2px;
                                }
                                p:nth-of-type(1){
                                    color: #ED2A24;
                                    margin: 4px 0 16px;
                                    &>span:nth-child(1){
                                        font-size: 18px;
                                    }
                                    &>span:nth-child(2){
                                        margin-top: 2px;
                                        margin-left: 5px;
                                        font-size: 10px;
                                        color: #999999;
                                        text-decoration:line-through;
                                    }
                                }
                            }
                        }
                    }
                }
                .goods-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid #F2F2F2;
                    padding: 10px 8px;
                    position: relative;
                    z-index: 2;
                    .gap {
                        font-size: 14px;
                        color: #666;
                        span {
                            color: #FF6445;
                            font-weight: bold;
                            font-size: 14px;
                            font-style:italic
                        }
                    }
                    .goods-btn {
                        display: flex;
                        >div {
                            width: 76px;
                            height: 26px;
                            position: relative;
                            border-radius: 16px;
                            background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                            span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                border-radius: 12px;
                                width: 74px;
                                height: 24px;
                                line-height: 24px;
                                background: #fff;
                                text-align: center;
                                font-size: 14px;
                                color: #FF4745;
                            }
                        }
                        >p {
                            margin-right: 8px;
                            display: inline-block;
                            padding: 6px 8px;
                            line-height: 1;
                            background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                            border-radius: 22px;
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .hot {
            margin-top: 0;
        }
        
    }
    .disable {
        pointer-events: none;
    }
</style>